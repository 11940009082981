<template>
  <sdCards>
    <template v-slot:title>
      <div class="setting-card-title">
        <sdHeading as="h4">Profiel</sdHeading>
      </div>
    </template>
    <a-row type="flex" justify="center">
      <a-col :xl="12" :lg="16" :xs="24" class="mb-10 mt-10">
        <BasicFormWrapper>
          <a-form layout="vertical">
            <a-form-Item label="Naam">
              <a-input v-model:value="profile.fullName" :disabled="true"/>
            </a-form-Item>
            <a-form-Item label="Email">
              <a-input v-model:value="profile.email" :disabled="true"/>
            </a-form-Item>
            <a-form-Item label="Gebruikersnaam">
              <a-input v-model:value="profile.username" :disabled="true"/>
            </a-form-Item>
            <a-form-Item label="Rol">
              <a-input v-model:value="profile.roleName" :disabled="true"/>
            </a-form-Item>
            <a-form-Item label="Organisatie">
              <a-input v-model:value="profile.organizationName" :disabled="true"/>
            </a-form-Item>
          </a-form>
        </BasicFormWrapper>
      </a-col>
    </a-row>
  </sdCards>
</template>
<script>
import { defineComponent, computed } from 'vue';
import {BasicFormWrapper} from "../../styled";
import {useStore} from "vuex";
import {getApiURL} from "../../../config/dataService/dataService";

const Profile = defineComponent({
  name: 'Profile',
  components: { BasicFormWrapper },

  setup() {
    const {state} = useStore();
    const profile = computed(() => state.profile.profile);

    return {
      profile
    };
  },

  data() {
    return {
      fileUrl: getApiURL(),
      inputVisible: false,
      inputValue: '',
    };
  },
});

export default Profile;
</script>
